/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html
            xmlns="http://www.w3.org/1999/xhtml"
            xmlLang="en"
            lang="en"
            dir="ltr"
          />
          <body className="not-front not-logged-in page-node node-type-detailspage no-sidebars" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width; initial-scale=1.0; maximum-scale=1.0;"
          />
          <title>|| MIGRALEVE™ ||</title>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="dcterms.date" content="2015-08-17T00:00:00Z" />
          <link
            rel="canonical"
            href="http://www.migraleve.co.uk/trigger-diary/"
          />
          <meta name="revisit-after" content="1 day" />
          <link
            type="text/css"
            rel="stylesheet"
            media="all"
            href="/sites/migraleve.co.uk/files/css/css_f5c0e4b8b5890495746045b5a64d49ec.css"
          />
          <script
            type="text/javascript"
            src="/sites/migraleve.co.uk/files/js/js_f73249d2b008e77bdf5314d75badf755.js"
          />
          <script
            type="text/javascript"
            innerHTML='
<!--//--><!--[CDATA[//--><!--
jQuery.extend(Drupal.settings, { "basePath": "/", "lightbox2": { "rtl": 0, "file_path": "/(\\w\\w/)sites/migraleve.co.uk/files", "default_image": "/sites/all/modules/contrib/lightbox2/images/brokenimage.jpg", "border_size": 10, "font_color": "000", "box_color": "fff", "top_position": "", "overlay_opacity": "0.8", "overlay_color": "000", "disable_close_click": true, "resize_sequence": 0, "resize_speed": 400, "fade_in_speed": 400, "slide_down_speed": 600, "use_alt_layout": false, "disable_resize": false, "disable_zoom": false, "force_show_nav": false, "show_caption": true, "loop_items": false, "node_link_text": "View Image Details", "node_link_target": false, "image_count": "Image !current of !total", "video_count": "Video !current of !total", "page_count": "Page !current of !total", "lite_press_x_close": "press \x3ca href=\"#\" onclick=\"hideLightbox(); return FALSE;\"\x3e\x3ckbd\x3ex\x3c/kbd\x3e\x3c/a\x3e to close", "download_link_text": "", "enable_login": false, "enable_contact": false, "keys_close": "c x 27", "keys_previous": "p 37", "keys_next": "n 39", "keys_zoom": "z", "keys_play_pause": "32", "display_image_size": "original", "image_node_sizes": "()", "trigger_lightbox_classes": "", "trigger_lightbox_group_classes": "", "trigger_slideshow_classes": "", "trigger_lightframe_classes": "", "trigger_lightframe_group_classes": "", "custom_class_handler": 0, "custom_trigger_classes": "", "disable_for_gallery_lists": true, "disable_for_acidfree_gallery_lists": true, "enable_acidfree_videos": true, "slideshow_interval": 5000, "slideshow_automatic_start": true, "slideshow_automatic_exit": true, "show_play_pause": true, "pause_on_next_click": false, "pause_on_previous_click": true, "loop_slides": false, "iframe_width": 600, "iframe_height": 300, "iframe_border": 1, "enable_video": false } });
//--><!--]]-->
'
          />

          <link
            type="text/css"
            rel="stylesheet"
            media="all"
            href="/css/style1.css"
          />

          <script
            innerHTML="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','globalDataLayer','GTM-TQDRJ93');"
          />
          <script src="https://code.jquery.com/jquery-3.4.1.min.js" />
          <script
            type="text/javascript"
            innerHTML="
function OptanonWrapper() { }
"
          />
        </Helmet>
        ;
      </React.Fragment>
    );
  }
}
