import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper1">
              <div id="block-menu-primary-links" className="block block-menu">
                <div className="content">
                  <ul className="menu">
                    <li className="leaf first home">
                      <a href="/home" title="Home">
                        <img src="/sites/migraleve.co.uk/files/images/home.png" />
                      </a>
                    </li>
                    <li className="expanded aboutmigraines">
                      <a href="/about-migraine" title="About Migraines" className="about-migraine">
                        ABOUT MIGRAINES
                      </a>
                      <ul className="menu">
                        <li className="leaf first migrainesymptoms">
                          <a href="/migraine-symptoms" title="Migraine Types & Symptoms">
                            Migraine Types & Symptoms
                          </a>
                        </li>
                        <li className="leaf potentialmigrainecauses">
                          <a
                            href="/migraine-causes"
                            title="Potential Migraine Causes"
                          >
                            Potential Migraine Causes
                          </a>
                        </li>
                        <li className="leaf last whatcanyoudoaboutit">
                          <a
                            href="/migraine-treatment-and-prevention"
                            title="Treatment and Relief"
                          >
                            Treatment and Relief
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="leaf ourproducts">
                      <a href="/migraine-relief-products" title="Our Products">
                        OUR
                        <br /> PRODUCTS
                      </a>
                    </li>
                    <li className="leaf migrainemanagementtips">
                      <a href="/migraine-tips" title="Migraine Management Tips">
                        MIGRAINE MANAGEMENT TIPS
                      </a>
                    </li>
                    <li className="leaf active-trail migrainetriggerdiary">
                      <a
                        href="/migraine-trigger-diary"
                        title="Migraine Trigger Diary"
                        className="active"
                      >
                        MIGRAINE TRIGGER DIARY
                      </a>
                    </li>
                    <li className="leaf last wheretobuy">
                      <a href="/where-to-buy" title="Where to buy">
                        WHERE TO BUY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="homeBannerWrapper">
                <div className="headingwrapper">
                  <div className="titlewrapper">
                    <h1>Migraine Trigger Diary</h1>
                    <h4 className="subheading">
                      <strong>Many people find that keeping a Migraine diary can help them recognise a pattern to their migraines</strong>.
                      They can then avoid one or more things that may trigger their migraine attacks.
                    </h4>
                  </div>
                  <div className="imagewrapper">
                    <img src="/sites/migraleve.co.uk/files/images/trigger-diary.jpg" />
                  </div>
                </div>

                <div className="innerPageContentWrapper">
                  <div className="innerPageContent">
                    <div id="node-14" className="node">
                      <div className="content clear-block">
                        <p>
                          Keeping a migraine diary may help you to find out what might be triggering your migraine attacks, or may make you more alert to warning signs. You may notice a pattern of things that happen in the two days before the migraine attack. If the tigger of your migraine attacks is a single thing, it can be simple to avoid. However, it may be harder to avoid attacks that are triggered by a combination of things.
                        </p>
                        <p>
                          For more information about what can trigger a
                          migraine, please read more on the{" "}
                          <a href="/migraine-causes">
                            Potential Migraine Causes
                          </a>{" "}
                          page.
                        </p>
                        <p>
                          Keeping track of the following details can help with
                          understanding your migraines:
                        </p>

                        <ul className="pointer">
                          <li>Date & time</li>
                          <li>What you were doing when the migraine started</li>
                          <li>What symptoms you experienced (including warning signs)</li>
                          <li>How long the migraine lasted</li>
                          <li>What medicines you took (if any)</li>
                        </ul>

                        <p>
                          For women, it can also be helpful to keep note when they start their period,
                          as this can help your GP identify potential triggers.
                        </p>
                        <p>
                          You will find a download of a migraine diary below. You might find it useful to fill it in over 3-4 months so that you and your doctor can develop a better understanding of your migraines.
                        </p>

                        <div className="text-center download_diarry">
                          <a href="/sites/migraleve.co.uk/files/pdf/Migraleve_Trigger_Diary.pdf">
                            <img src="/sites/migraleve.co.uk/files/images/td.png" />
                            <h3>
                              Your Trigger Diary
                            </h3>
                            <h4>
                              Download here
                            </h4>
                          </a>
                        </div>
                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                  <div className="innerPagePromo">
                    <div id="block-block-6" className="block block-block">
                      <div className="content">
                        <div className="migrane_tips">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/ma.png"
                            />
                            <div>
                              <h2>tips</h2>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a title="Tips" href="/tips">
                                Find out more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="where_to_buy">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/am.png"
                            />
                            <div>
                              <h2>About migraines</h2>
                              <p>
                                What is a migraine? What causes it? What can you
                                do about it?
                              </p>
                              <a title="Get the answers" href="/migraine-symptoms">
                                Get the answers
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buying_migraleve">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/bm.png"
                            />
                            <div>
                              <h2>
                                buying migraleve<sup>™</sup>
                              </h2>
                              <p>
                                Find your nearest stockist of Migraleve
                                products.
                              </p>
                              <a title="Where to buy" href="/where-to-buy">
                                Where to buy
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="footerContent">
                <div
                  id="block-menu-menu-site-footer-menu"
                  className="block block-menu"
                >
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first privacypolicy">
                        <a href="/privacy-policy" title="Privacy policy">
                          Privacy policy
                        </a>
                      </li>
                      <li className="leaf legalnotice">
                        <a href="/legal-notice" title="Legal notice">
                          Legal notice
                        </a>
                      </li>
                      <li className="leaf contactus">
                        <a href="/contact-us" title="Contact us">
                          Contact us
                        </a>
                      </li>
                      <li className="leaf cookiepolicy">
                        <a href="/cookie-policy" title="Cookie Policy">
                          Cookie Policy
                        </a>
                      </li>
                      <li className="leaf modernslaveryactstatement">
                        <a
                          href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                          target="_blank"
                        >
                          Modern Slavery Act Statement
                        </a>
                      </li>
                      <li className="leaf last cookiesettings">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <p>
                      <strong>
                        Always read the label. Ask your pharmacist for advice.
                      </strong>
                    </p>
                    <p>
                      MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                      codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                      codeine and buclizine. MIGRALEVE<em>™</em> Yellow contains
                      paracetamol and codeine.
                    </p>
                    <p>
                      Can cause addiction. For three days use only. This
                      medicine can make you feel sleepy. Do not drive while
                      taking this medicine until you know how it makes you feel.
                    </p>
                    <p>
                      This site is published by Johnson &amp; Johnson Limited
                      which is solely responsible for its contents. It is
                      intended for a UK audience. MIGRALEVE<em>™</em> is a
                      registered trademark.
                    </p>
                    <p>
                      This site is best viewed with the following web browsers:
                      Internet Explorer version 8, Firefox version 2+, Safari
                      3+, Chrome version 3+.
                    </p>
                    <p>
                      © Johnson &amp; Johnson Limited 2024. This site was last
                      updated on: 15<sup>th </sup>August 2024
                    </p>
                  </div>
                </div>
                <p>
                  <a
                    id="popup-trigger"
                    href="/sites/migraleve.co.uk/files/pop-up-text"
                    rel="lightmodal"
                  >
                    hide this
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
